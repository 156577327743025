import React, { useEffect, useState } from "react";
import { Row, Col, Button, Offcanvas, Modal } from "react-bootstrap";
import { BsFillPlayCircleFill, BsFilm, BsPlayFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import AgePop from "../PopUp/AgePop";
import { APP_COLORS, APP_IMAGES } from "../common/Api";
import instance from "../common/Api";

const OverView = ({ title, director, cast, genres, desc, data, props }) => {
  const [showDialogAge, setShowDialogAge] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedVideoPoster, setSelectedVideoPoster] = useState(null);
  // const [Is, setShowDialogAge] = useState(false);
  const [show, setShow] = useState(false);
  const [videoType, setVideoType] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [userIP, setUserIP] = useState(null);

  const formatGenresWeb = (genres) => {
    if (!genres || genres.length === 0) {
      return null;
    }

    return genres.join(" | ");
  };

  const formatCastWeb = (cast) => {
    if (!cast || cast.length === 0) {
      return null;
    }

    return cast.map((actor) => actor.name).join(" | ");
  };

  // const handleClick = () => {
  //   if (data && data.age === "18+") {
  //     //console.log("handleClick");
  //     setShowDialogAge(true);
  //   } else {
  //     navigate("/play", { state: { someData: data, type: "main" } });
  //   }
  // };

  const handleClosePop = () => {
    setShowDialogAge(false);
  };

  const containsTrailer = (str) => {
    return str.includes("Trailer");
  };

  const handleClick = (type) => {
    //console.log("type from overview:", type);
    setVideoType(type);
    if (props.userData != null) {
      if (data && data.age === "18+") {
        //console.log("handleClick");
        setShowDialogAge(true);
      } else {
        navigate("/play", {
          state: { someData: data, type: videoType },
        });
      }
    } else {
      navigate("/subscribe");
    }
  };

  const playVideo = (src, poster) => {
    setSelectedVideo(src);
    setSelectedVideoPoster(poster);
    if (props.userData != null) {
      if (containsTrailer(src)) {
        if (data && data.age === "18+") {
          setShowDialogAge(true);
        } else {
          navigate("/play", {
            state: { src: src, poster: poster },
          });
        }
      } else {
        //console.log(props.userData.isSubscribed);
        if (isSubscribed) {
          if (data && data.age === "18+") {
            //console.log("handleClick");
            setShowDialogAge(true);
          } else {
            navigate("/play", {
              state: { src: src, poster: poster },
            });
          }
        } else {
          navigate("/subscribe");
        }
      }
    } else {
      navigate("/login");
    }

    // if (props.userData != null) {
    //   if (props.userData.isSubscribed === "true" && containsTrailer(src)) {
    //     if (data && data.age === "18+") {
    //       //console.log("handleClick");
    //       setShowDialogAge(true);
    //     } else {
    //       navigate("/play", {
    //         state: { src: src, poster: poster },
    //       });
    //     }
    //   } else {
    //     navigate("/subscribe");
    //   }
    // } else {
    //   navigate("/login");
    // }
  };

  const handleAge = (value) => {
    //console.log("value", value);
    if (value == "yes") {
      //console.log("type from overview:videoType", videoType);
      navigate("/play", {
        state: { src: selectedVideo, poster: selectedVideoPoster },
      });
      // navigate("/play", {
      //   state: { someData: data, type: videoType },
      // });
    }
  };

  const acceptWarning = () => {
    navigate("/play", {
      state: { src: selectedVideo, poster: selectedVideoPoster },
    });

    // if (isMobile) {
    //   navigate("/play", { state: { someData: mediaData, type: types } });
    // } else {
    //   navigate("/play", {
    //     state: { src: selectedVideo, poster: selectedVideoPoster },
    //   });
    // }
  };

  const checkSubscribtion = () => {
    // console.log(props.userData, "URR");
    const fetchUserIP = async () => {
      try {
        // Update IP using your method to get the user's IP
        const ip = await window.getUserIP();
        console.log("Fetched IP:", ip);

        // Assuming 'Unknown' means IP could not be determined
        if (ip !== "Unknown") {
          setUserIP(ip); // Set userIP state only if it's not 'Unknown'
        }
      } catch (error) {
        console.error("Error fetching user IP:", error);
      }
    };
    fetchUserIP().then(() => {
      if (props.userData != null) {
        instance
          .post(`/subscription_plans_web?id=${props.userData.id}&ip=${userIP}`)
          .then((response) => {
            //console.log("response.data", response.data);
            const active = response.data.active_plan;
            console.log(active[0], "<active");
            if (response.data && response.data.active_plan.length > 0) {
              // setActivePlans(response.data.active_plan[0]);
              setIsSubscribed(true);
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          })
          .finally(() => {
            setLoading(false); // Set loading to false when fetch is complete
          });
        //console.log("User ID:", props.userData.id);
      } else {
        //console.log("User ID not found");
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    //console.log("OVERVIEW: ", props.userData.isSubscribed);
    // if (props.userData != null) {
    // }
    checkSubscribtion();
  }, []);

  return (
    <div className="text-white">
      {showDialogAge && (
        <Modal
          show={showDialogAge}
          onHide={handleClosePop}
          style={{ marginTop: "10rem" }}
        >
          <Modal.Body
            style={{
              backgroundImage: `url(${APP_IMAGES.POP_UP_BG})`,
              backgroundSize: "cover",
              color: "white",
              textAlign: "center",
              paddingTop: "20px",
              paddingBottom: "20px",

              // height: "15rem", // Adjust as needed
              backgroundColor: "black",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1
                style={{
                  paddingBottom: "1rem",
                  width: "50px",
                  height: "50px",
                  textAlign: "center", // Center text horizontally
                  lineHeight: "50px", // Center text vertically
                  backgroundColor: "white",
                  borderRadius: "50%",
                  color: "black",
                }}
              >
                A
              </h1>
            </div>
            <h2 style={{ paddingBottom: "1rem" }}>
              This is A rated Movie <br />
              Press Yes to continue
            </h2>
            <Col></Col>
            <Button
              variant="secondary"
              style={{
                color: "white",
                backgroundColor: APP_COLORS.APP_DARK_1,
              }}
              onClick={() => acceptWarning()}
            >
              Yes
            </Button>
            &nbsp;&nbsp;
            <Button
              variant="secondary"
              style={{
                color: "white",
                backgroundColor: "#7e7e7e",
              }}
              onClick={() => handleClosePop()}
            >
              No
            </Button>
          </Modal.Body>
        </Modal>
        // <AgePop
        //   show={showDialogAge}
        //   handleClose={() => setShowDialogAge(!showDialogAge)}
        //   handleFilter={handleAge}
        // />
      )}
      <h2>{title}</h2>
      <p>{desc}</p>
      <Row className="innerRowcastDirectorBox_web pt-4 pb-4">
        <Col md={1}>
          <h5 className="title mt-0 mb-0">Director</h5>
        </Col>
        <Col md={11} className="d-flex align-items-center">
          <p className="directorListBox">{director}</p>
        </Col>
        <Col md={1}>
          <h5 className="title mt-0 mb-0">Cast</h5>
        </Col>
        <Col md={11} className="d-flex align-items-center">
          <p className="castListBox">{cast && formatCastWeb(cast)}</p>
          {/* <div>
            {cast &&
              cast.map((item, idx) => (
                <p key={idx} className="castListBox">
                  {item.name}
                </p>
              ))}
          </div> */}
        </Col>
        <Col md={1} pt={2} pb={2} style={{ marginTop: "5px" }}>
          <h5 className="title mt-0 mb-0">Genres</h5>
        </Col>
        <Col md={11} className="d-flex align-items-center">
          <div>
            {/* {genres.map((genre, index) => ( */}
            <p className="castListBox">{genres && formatGenresWeb(genres)}</p>
            {/* ))} */}
          </div>
        </Col>
        <Col md={12} className="pt-3 pb-5">
          <Button
            className="loader"
            variant="primary"
            onClick={() => playVideo(data.video, data.mobile_image)}
            style={{
              height: "40px",
              minWidth: "150px",
              borderRadius: "20px",
              backgroundColor: APP_COLORS.APP_DARK_1,
              borderWidth: "0",
            }}
          >
            <BsPlayFill
              size={20}
              className="mr-2"
              style={{
                marginBottom: "3px",
                marginRight: "5px",
                backgroundColor: APP_COLORS.APP_DARK_1,
              }}
            />
            Play
          </Button>
          <Button
            onClick={() => playVideo(data.trailer_video, data.mobile_image)}
            // onClick={() => handleClick("trailer")}
            variant="primary"
            style={{
              height: "40px",
              minWidth: "150px",
              borderRadius: "20px",
              borderWidth: "0",
              backgroundColor: APP_COLORS.APP_DARK_2,
              marginLeft: "10px",
              color: "#000000",
            }}
          >
            <BsFillPlayCircleFill
              size={20}
              className="mr-2"
              style={{ marginBottom: "4px", marginRight: "5px" }}
            />
            Watch Trailer
          </Button>
          {/* <Button style={{ height: '40px', minWidth: '150px', borderRadius: '20px', backgroundColor: "#e20788" }}>
      <BsFillPlayCircleFill size={20} className="mr-2" />
      Watch Trailer
    </Button> */}
        </Col>
      </Row>
      {/* <Offcanvas show={show} onHide={handleClose} placement='bottom'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Babu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
         Oopss... You are not logged in please login to continue...
        </Offcanvas.Body>
      </Offcanvas> */}
      {/* {showDialogAge && (
        <AgePop
          show={showDialogAge}
          handleClose={() => setShowDialogAge(!showDialogAge)}
          handleFilter={handleAge}
        />
      )} */}
    </div>
  );
};

export default OverView;
