import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const PaymentStatusPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect after 2 seconds
    const timer = setTimeout(() => {
      //   window.location.href = "https://bit.ly/Olaplepaymentstatus";
    }, 2000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div
      style={{
        backgroundImage: "url(./main-bg-web.jpg)",
        backgroundRepeat: "round",
        backgroundSize: "cover",
        backgroundBlendMode: "overlay",
        height: "55rem",
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-5" style={{ marginTop: "15rem" }}>
            <div
              className="message-box _success"
              style={{
                // boxShadow: "0 15px 25px #00000019",
                boxShadow: "0 15px 25px rgba(0, 0, 0, 1)",
                padding: "45px",
                width: "100%",
                textAlign: "center",
                margin: "40px auto",
                borderBottom: "solid 4px #DCDF39",
              }}
            >
              {/* Logo image */}
              <img
                src="https://www.kidullan.com/header_logo.png"
                className="d-inline-block align-top"
                alt="Logo"
                style={{ height: "30px", marginBottom: "30px" }}
              />
              {/* Success message */}
              <p
                style={{
                  color: "white",
                  marginBottom: "0px",
                  fontSize: "18px",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Your order is being processed. Please allow a few minutes for
                confirmation.
                <br />
                We are redirecting you to your profile.
                <br />
                Thank you.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentStatusPage;
