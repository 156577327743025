import instance from "../common/Api";
import "./Cookies.css";
import React, { useEffect, useState } from "react";
import Loader from "../common/Loader";

const ContactUs = () => {
  const [htmlContent, setHtmlContent] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    instance.get("/getPage/2").then((response) => {
      let data = response.data.description;

      // Ensure data is a string
      if (typeof data !== "string") {
        data = JSON.stringify(data);
      }

      const tagsToColorGreen = ["h3", "h2", "a"]; // Add more tags if needed

      // Set the HTML content and manipulate it to set text color to green for specified tags
      let modifiedHtmlContent = data;
      tagsToColorGreen.forEach((tag) => {
        modifiedHtmlContent = modifiedHtmlContent.replace(
          new RegExp(`<${tag}(.*?)>`, "g"),
          `<${tag} style="color: white;"$1>`
        );
      });
      setLoading(false);
      setHtmlContent(modifiedHtmlContent);
    });
  }, []);

  return (
    <div
      className="container-fluid privacyPolicyImgbox"
      style={{
        width: "100%",
        minHeight: "51rem",
        position: "relative",
        backgroundBlendMode: "overlay",
      }}
    >
      {loading && (
        <div className="loading-overlay">
          <Loader isLoading={loading} />
        </div>
      )}
      <div className="container privacyPolicyBellowTextBox">
        <div id="scroll">
          <div className="row">
            {htmlContent && (
              <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
            )}
          </div>
        </div>
      </div>
    </div>
    // <div
    //   className="container-fluid privacyPolicyImgbox"
    //   style={{
    //     width: "100%",
    //     minHeight: "51rem",
    //     position: "relative",
    //     // backgroundImage: `url(./bebuAppBG.JPEG)`,
    //     // backgroundSize: "cover",
    //     // backgroundPosition: "center",
    //     // backgroundColor: 'transperent',
    //     backgroundBlendMode: "overlay",
    //   }}
    // >
    //   <div className="container privacyPolicyBellowTextBox">
    //     <div id="scroll">
    //       <div className="row">
    //         <div className="col-md-12 privacyContentBox">
    //           <h3
    //             style={{
    //               color: "white",
    //               paddingBottom: "10px",
    //               paddingTop: "50px",
    //             }}
    //           >
    //             CONTACT US
    //           </h3>

    //           <h5
    //             style={{
    //               color: "#FFFFFFCC",
    //               paddingTop: "50px",
    //               paddingBottom: "10px",
    //             }}
    //           >
    //             Mail us at{" "}
    //             <a
    //               href="mailto:info@bebu.app"
    //               style={{ color: "#e20788", textDecoration: "none" }}
    //             >
    //               info@bebu.app
    //             </a>{" "}
    //           </h5>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default ContactUs;
