import React, { useEffect } from "react";
import "./Subscribe.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Row,
  Col,
  Image,
  Modal,
  ModalHeader,
  Dropdown,
  Button,
} from "react-bootstrap";
import ZoomImage from "./common/ImageHover";
import { useState } from "react";
import { useUser } from "../UserContext";
import instance, { APP_COLORS, APP_IMAGES } from "./common/Api";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Loader from "./common/Loader";
import { useLocation } from "react-router-dom";

const Subscription = (props) => {
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredGold, setIsHoveredGold] = useState(false);
  const [isHoveredSilver, setIsHoveredSilver] = useState(false);
  const [isHoveredBasic, setIsHoveredBasic] = useState(false);
  const [newUser, setNewUser] = useState(null);
  const [basicPlans, setBasicPlans] = useState(null);
  const [silverPlans, setSilverPlans] = useState(null);
  const [goldPlans, setGoldPlans] = useState(null);
  const [sixDaysPlans, setSixDaysPlans] = useState(null);
  const [showAgePopUp, setShowAgePopUp] = useState(false);
  const [activePlans, setActivePlans] = useState(null);

  const [showPopUp, setShowPopUp] = useState(false);

  const [selectedAge, setSelectedAge] = useState("18 years");
  const [locationData, setLocationData] = useState(null);
  const [userCity, setUserCity] = useState(null);
  const [ApplocationData, setAppLocationData] = useState(null);

  const [popUpPlan, setPopUpPlan] = useState(null);
  const [popUpOldPrice, setPopUpOldPrice] = useState(null);
  const [popUpPrice, setPopUpPrice] = useState(null);
  const [popUpSub_id, setPopUpSub_id] = useState(null);
  const [isChecked, setIsChecked] = useState(true);
  const [showPayOptions, setShowPayOptions] = useState(false);
  const [ageIdx, setAgeIdx] = useState(0);
  const [userIP, setUserIP] = useState(null);
  // const [finalPayload, setFinalPayload] = useState({});
  // const [payloadData, setPayloadData] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const directUID = queryParams.get("id");

  console.log(directUID);
  // const { userData } = useUser();

  const fetchUserIP = async () => {
    //UPDATE IP
    const ip = await window.getUserIP();
    // console.log(ip, "....");
    if (ip === "Unknown") {
      setUserIP(ip);
    } else {
      setUserIP("45.115.53.96");
    }
  };

  const [plans, setPlans] = useState([]);
  // Function to filter plans based on title and set states
  const filterPlans = (plans) => {
    const gold = plans.content.filter(
      (plan) => plan.title.toLowerCase() === "gold"
    );
    const basic = plans.filter((plan) => plan.title.toLowerCase() === "basic");
    // Filter other plan types if needed

    setGoldPlans(gold);
    setBasicPlans(basic);
    // Set states for other plan types if needed
  };
  const formatDate = function (dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${day}-${month}-${year}`;
  };

  const pushToLogin = () => {
    navigate("/login");
  };
  const FetchUser = () => {
    const storedUserData = localStorage.getItem("OlapleAppUser");
    const storedSocial = localStorage.getItem("SocialLogin");
    // ocalStorage.setItem("SocialLogin", true);
    return storedUserData ? JSON.parse(storedUserData) : null;
  };

  const MakePhonePePay = async () => {
    //console.log("MakePhonePePay", payload.amount);
    //subscription_id
    // ip
    if (selectedAge === "" || selectedAge === "Select your age") {
      alert("Please select your age");
    } else {
      const payload = {
        cityName: userCity,
        age: selectedAge,
        amount: popUpPrice,
        ip: userIP,
        id: directUID,
        subscription_id: popUpSub_id,
      };

      // console.error("Error fetching payload:", payload);
      instance
        .post(
          `/user/phone_pe?amount=${payload.amount}&subscription_id=${payload.subscription_id}&id=${payload.id}&ip=${payload.ip}&cityName=${payload.cityName}&age=${payload.age}&payment_from=android`
        )
        .then((response) => {
          window.location.href =
            response.data.data.instrumentResponse.redirectInfo.url;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          // window.location.href = "/";
          setLoading(false); // Set loading to false when fetch is complete
        });
      setShowPopUp(false);
      setShowPayOptions(false);
    }
  };

  const makePayment = (data) => {
    // //console.log("makePayment", data);
    // //console.log("data.listedPrice", data.listedPrice);
    // //console.log("data.subscription_id", data.subscription_id);
    // //console.log("props.ip", props.ip);
    // //console.log("userData", props.userData.id);
    setPopUpPlan(data.title);
    setPopUpOldPrice(data.amount);
    setPopUpPrice(data.listedPrice);
    setPopUpSub_id(data.subscription_id);

    // setPayloadData(data);
    // if (selectedAge !== "Select you age") {
    //   const payload = {
    //     city: userCity,
    //     age: selectedAge,
    //     amount: data.listedPrice,
    //     ip: props.ip,
    //     id: props.userData.id,
    //     subscription_id: data.subscription_id,
    //   };
    //   // setFinalPayload(payload);

    //   // MakePhonePePay(payload);
    // } else {
    // }
    setShowPopUp(true);
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked); // Update the state based on checkbox's checked value
  };

  const ageArray = Array.from(
    { length: 99 - 18 + 1 },
    (_, index) => index + 18
  );

  const handleSelectChange = (event, idx) => {
    setAgeIdx(idx);
    // console.log(`${event.target.value + " years"}`);
    console.log(`${event + " years"}`);
    if (event !== null) {
      setSelectedAge(`${event + " years"}`); // Update selectedAge state with the value of the selected option
    }
  };
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchUserIP();
    if (userIP !== null) {
      if (directUID != null) {
        instance
          .post(`/subscription_plans_web?id=${directUID}&ip=${userIP}`)
          .then((response) => {
            setSubscriptionData(response.data);
            //console.log("response.data", response.data);
            const active = response.data.active_plan;
            //console.log(active[0], "<active");
            if (response.data && response.data.active_plan.length > 0) {
              setActivePlans(response.data.active_plan[0]);
            }
            //console.log(response.data.active_plan);

            const goldPlans = response.data.content.filter(
              (plan) => plan.title.toLowerCase() === "gold"
            );
            const silverPlans = response.data.content.filter(
              (plan) => plan.title.toLowerCase() === "silver"
            );
            const basicPlans = response.data.content.filter(
              (plan) => plan.title.toLowerCase() === "basic"
            );
            const sixDaysPlans = response.data.content.filter(
              (plan) => plan.title.toLowerCase() === "premium"
            );
            //console.log("Basic Plans:", goldPlans[0]);
            setBasicPlans(basicPlans[0]);
            setSilverPlans(silverPlans[0]);
            setGoldPlans(goldPlans[0]);
            setSixDaysPlans(sixDaysPlans[0]);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          })
          .finally(() => {
            setLoading(false); // Set loading to false when fetch is complete
          });
        //console.log("User ID:", props.userData.id);
      } else {
        //console.log("User ID not found");
        setLoading(false);
      }
      if (directUID != null) {
        instance
          .post(`/getUsersState?ip=${userIP}&id=${directUID}`)
          .then((response) => {
            // setAppLocationData(response.data);
            console.log("/getUsersState", response.data);
            console.log("/getUsersState", response.data[0].cityName);
            console.log("/getUsersState", response.data[0].countryName);
            if (response.data[0].countryName === "India") {
              setUserCity(response.data[0].cityName);
            } else {
              setUserCity(null);
            }

            console.log("/getUsersState", response.data[1].states);
            setAppLocationData(response.data[1].states);
            //console.log(active[0], "<active");
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          })
          .finally(() => {
            setLoading(false); // Set loading to false when fetch is complete
          });
        //console.log("User ID:", props.userData.id);
      } else {
        //console.log("User ID not found");
        setLoading(false);
      }
    }

    // filterPlans(subscriptionData);
    //console.log("subscriptionData", props.userData);
  }, [userIP]);

  return (
    <div
      style={
        props.isMobile
          ? {
              width: "100%",
              minHeight: "55rem",
              // position: "relative",
              backgroundImage: `url(${APP_IMAGES.APP_MAIN_BG_MOB})`,
              backgroundSize: "cover",
              // backgroundPosition: "center",
              // backgroundColor: "#141414",
              backgroundRepeat: "repeat-y",
              backgroundBlendMode: "overlay",
            }
          : {
              backgroundImage: `url(${APP_IMAGES.APP_MAIN_BG_WEB})`,
              backgroundSize: "cover",
              // backgroundPosition: "center",
              // backgroundColor: "#141414",
              backgroundRepeat: "repeat-y",
              backgroundBlendMode: "overlay",
            }
      }
    >
      <Modal
        show={showPopUp}
        onHide={() => {
          setShowPopUp(false);
          setShowPayOptions(false);
        }}
        style={{ marginTop: "2rem" }}
      >
        <ModalHeader
          style={{
            padding: "0",
            backgroundColor: APP_COLORS.APP_NAV_COLOR,
            borderBottom: "none",
          }}
        >
          <h6
            style={{
              marginTop: "20px",
              marginLeft: "10px",
              color: APP_COLORS.APP_WHITE,
            }}
          >
            Select payment options for subscribing
          </h6>{" "}
        </ModalHeader>
        <Modal.Body
          style={{
            backgroundImage: `url(${APP_IMAGES.POP_UP_BG})`,
            backgroundSize: "cover",
            color: "white",
            textAlign: "center",
            paddingTop: "20px",
            paddingBottom: "20px",
            // borderRadius: "10px",
            // height: "15rem", // Adjust as needed
            backgroundColor: "black",
          }}
        >
          <div
            style={{
              // maxHeight: "300px", // Set your desired fixed height here
              //overflowY: "scroll",
              width: "100%", // Ensure full width
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "10px",
              }}
            >
              <p style={{ display: "inline", color: APP_COLORS.APP_DARK_2 }}>
                {popUpPlan && popUpPlan}
              </p>
              <p
                style={{
                  display: "inline",
                  marginLeft: "auto",
                  textDecoration: "line-through",
                  color: "#008dc4",
                }}
              >
                ₹ {popUpOldPrice && popUpOldPrice}
              </p>

              <p style={{ display: "inline", color: APP_COLORS.APP_DARK_1 }}>
                ₹ {popUpPrice && popUpPrice}
              </p>
            </div>
            {showPayOptions ? (
              <>
                {/* <Button
                  variant="secondary"
                  style={{
                    marginTop: "20px",
                    color: "white",
                    width: "100%",
                    backgroundColor: "#008dc4",
                  }}
                  // onClick={MakePhonePePay}
                >
                  Debit Card | Credit Card | Net Banking
                </Button> */}
                {/* <Button
                  variant="secondary"
                  style={{
                    marginTop: "20px",
                    color: "white",
                    width: "100%",
                    backgroundColor: "#008dc4",
                  }}
                  // onClick={MakePhonePePay}
                >
                  Google Pay | UPI Payment
                </Button> */}
                <Button
                  variant="secondary"
                  style={{
                    marginTop: "20px",
                    color: "white",
                    width: "100%",
                    backgroundColor: "#008dc4",
                  }}
                  onClick={MakePhonePePay}
                >
                  PhonePe | UPI Payment
                </Button>
              </>
            ) : (
              <>
                {userCity && (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="light"
                      id="dropdown-basic"
                      style={{ color: APP_COLORS.APP_DARK_1 }}
                    >
                      {userCity && userCity}
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        height: "10rem",
                        overflowY: "auto",
                        backgroundColor: "#008dc4",
                      }}
                    >
                      {ApplocationData &&
                        ApplocationData.map((item, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() => setUserCity(item.name)}
                          >
                            {item.name}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                <p
                  style={{
                    marginRight: "auto",
                    fontWeight: "700",
                    color: APP_COLORS.APP_WHITE,
                  }}
                >
                  Select your age:{" "}
                </p>
                <div
                  style={{
                    maxHeight: "8rem", // Set your desired fixed height here
                    overflowY: "scroll",
                    backgroundColor: "#008dc4",
                    borderRadius: "10px",
                    padding: "10px",
                    width: "100%",
                  }}
                >
                  {ageArray &&
                    ageArray.map((item, idx) => (
                      <p
                        key={idx}
                        style={{
                          color: "white",
                          cursor: "pointer",
                          backgroundColor:
                            ageIdx === idx ? APP_COLORS.APP_DARK_1 : "",
                          borderRadius: "3px",
                        }}
                        onClick={() => handleSelectChange(item, idx)}
                      >
                        {item} Years
                      </p>
                    ))}
                </div>

                <div>
                  <p style={{ fontSize: "12px", marginTop: "10px" }}>
                    <input
                      type="checkbox"
                      style={{
                        marginRight: "5px",
                        color: isChecked
                          ? APP_COLORS.APP_DARK_1
                          : APP_COLORS.APP_WHITE,
                      }}
                      checked={isChecked}
                      onChange={handleCheckboxChange} // Call handleCheckboxChange function on checkbox change
                    />
                    <span style={{ color: APP_COLORS.APP_WHITE }}>
                      I have Read and Accepted the{" "}
                    </span>
                    <span style={{ color: APP_COLORS.APP_DARK_1 }}>
                      Terms and condition
                    </span>
                    <span style={{ color: APP_COLORS.APP_WHITE }}> and </span>
                    <span style={{ color: APP_COLORS.APP_DARK_1 }}>
                      Privacy Policy
                    </span>
                    <span style={{ color: APP_COLORS.APP_WHITE }}> and </span>
                    <span style={{ color: APP_COLORS.APP_DARK_1 }}>
                      Refund Policy
                    </span>
                    <span style={{ color: APP_COLORS.APP_WHITE }}>.</span>
                  </p>
                </div>
                <Button
                  variant="secondary"
                  disabled={!isChecked || selectedAge === null}
                  style={{
                    marginTop: "20px",
                    color: "white",
                    backgroundColor: "#008dc4",
                  }}
                  onClick={() => setShowPayOptions(true)}
                >
                  Continue
                </Button>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
      {directUID && directUID != null ? (
        <>
          {activePlans != null ? (
            <>
              <Container style={{ padding: "20px", minHeight: "55rem" }}>
                {loading && (
                  <div className="loading-overlay">
                    <Loader isLoading={loading} />
                  </div>
                )}
                <Container>
                  <Row className="justify-content-center">
                    <Col xs={12} className="text-center text-white">
                      <h1 style={{ color: APP_COLORS.APP_DARK_1 }}>
                        Your Subscribed Package
                      </h1>
                    </Col>
                  </Row>
                </Container>
                <Row style={{ padding: "20px" }}>
                  <Col xs={12} md={2}></Col>
                  <Col
                    md={8}
                    className={`d-flex flex-column align-items-center mb-4 ${
                      isHoveredGold ? "zoomed-card" : ""
                    }`}
                  >
                    <Image
                      src={APP_IMAGES.SUB_BG_IMAGE}
                      style={{
                        paddingBottom: "20px",
                        maxWidth: "100%",
                        height: "auto",
                      }}
                    />
                    <h1 className="month">
                      12
                      <br />
                      Months
                    </h1>
                    <h4 className="rupee">₹</h4>
                    <h1 className="offerPrice">
                      {activePlans.subscription_amount}
                    </h1>
                    <h1 className="planName">{activePlans.title}</h1>
                  </Col>
                  <Col md={2}></Col>
                </Row>
                {/* <Row style={{ padding: "20px" }}>
                  <Col md={3}></Col>
                  <Col md={2}>
                    <div
                      style={{
                        backgroundColor: APP_COLORS.APP_DARK_1,
                        color: "white",
                        textAlign: "center",
                        padding: "10px",
                        margin: "5px", // Added margin for spacing
                        borderRadius: "9px",
                        width: "auto",
                      }}
                    >
                      <h6>User</h6>
                      
                    </div>
                  </Col>
                  <Col md={2}>
                    <div
                      style={{
                        backgroundColor: APP_COLORS.APP_DARK_1,
                        color: "white",
                        textAlign: "center",
                        padding: "10px",
                        margin: "5px", // Added margin for spacing
                        borderRadius: "9px",
                      }}
                    >
                      <h6>Expiry Date</h6>
                      <h4>{formatDate(activePlans.expiry_date)}</h4>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div
                      style={{
                        backgroundColor: APP_COLORS.APP_DARK_1,
                        color: "white",
                        textAlign: "center",
                        padding: "10px",
                        margin: "5px",
                        borderRadius: "9px",
                        width: "auto",
                      }}
                    >
                      <h6>Transaction Id</h6>
                      <h4>XXXX{activePlans.transaction_id}</h4>
                    </div>
                  </Col>
                  <Col md={3}></Col>
                </Row> */}
              </Container>
            </>
          ) : (
            <>
              <Container style={{ padding: "20px", minHeight: "55rem" }}>
                {loading && (
                  <div className="loading-overlay">
                    <Spinner animation="border" />
                  </div>
                )}
                <Container>
                  <Row className="justify-content-center">
                    <Col xs={12} className="text-center text-white">
                      <h1 style={{ color: APP_COLORS.APP_DARK_1 }}>
                        Subscription Packages
                      </h1>
                    </Col>
                  </Row>
                </Container>
                <Row style={{ padding: "20px" }}>
                  {goldPlans && (
                    <Col
                      xs={12}
                      md={6}
                      className={`d-flex flex-column align-items-center mb-4 ${
                        isHoveredGold ? "zoomed-card" : ""
                      }`}
                      onMouseEnter={() => setIsHoveredGold(true)}
                      onMouseLeave={() => setIsHoveredGold(false)}
                      onClick={() => makePayment(goldPlans)}
                    >
                      <Image
                        src={APP_IMAGES.SUB_BG_IMAGE}
                        style={{
                          paddingBottom: "20px",
                          maxWidth: "100%",
                          height: "auto",
                        }}
                      />
                      <h1 className="month">
                        12
                        <br />
                        Months
                      </h1>
                      <h4 className="rupee">₹</h4>
                      <h1 className="offerPrice">{goldPlans.listedPrice}</h1>
                      <h1 className="planName">{goldPlans.title}</h1>
                      <h4 className="oldPrice">₹ {goldPlans.amount}</h4>
                    </Col>
                  )}
                  {silverPlans && (
                    <Col
                      xs={12}
                      md={6}
                      className={`d-flex flex-column align-items-center mb-4 ${
                        isHoveredSilver ? "zoomed-card" : ""
                      }`}
                      onMouseEnter={() => setIsHoveredSilver(true)}
                      onMouseLeave={() => setIsHoveredSilver(false)}
                      onClick={() => makePayment(silverPlans)}
                    >
                      <Image
                        src={APP_IMAGES.SUB_BG_IMAGE}
                        style={{
                          paddingBottom: "20px",
                          maxWidth: "100%",
                          height: "auto",
                        }}
                      />
                      <h1 className="month">
                        3<br />
                        Months
                      </h1>
                      <h4 className="rupee">₹</h4>
                      <h1 className="offerPrice">{silverPlans.listedPrice}</h1>
                      <h1 className="planName">{silverPlans.title}</h1>
                      <h4 className="oldPrice">₹ {silverPlans.amount}</h4>
                    </Col>
                  )}
                </Row>
                <Row>
                  {basicPlans && (
                    <Col
                      xs={12}
                      md={6}
                      className={`d-flex flex-column align-items-center mb-4 ${
                        isHoveredBasic ? "zoomed-card" : ""
                      }`}
                      onMouseEnter={() => setIsHoveredBasic(true)}
                      onMouseLeave={() => setIsHoveredBasic(false)}
                      onClick={() => makePayment(basicPlans)}
                    >
                      <Image
                        src={APP_IMAGES.SUB_BG_IMAGE}
                        style={{
                          paddingBottom: "20px",
                          maxWidth: "100%",
                          height: "auto",
                        }}
                      />
                      <h1 className="month">
                        1<br />
                        Month
                      </h1>
                      <h4 className="rupee">₹</h4>
                      <h1 className="offerPrice">{basicPlans.listedPrice}</h1>
                      <h1 className="planName">{basicPlans.title}</h1>
                      <h4 className="oldPrice">₹ {basicPlans.amount}</h4>
                    </Col>
                  )}
                  {sixDaysPlans && (
                    <Col
                      xs={12}
                      md={6}
                      className={`d-flex flex-column align-items-center mb-4 ${
                        isHovered ? "zoomed-card" : ""
                      }`}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                      onClick={() => makePayment(sixDaysPlans)}
                    >
                      <Image
                        src={APP_IMAGES.SUB_BG_IMAGE}
                        style={{
                          paddingBottom: "20px",
                          maxWidth: "100%",
                          height: "auto",
                        }}
                      />
                      <h1 className="month">
                        12
                        <br />
                        Months
                      </h1>
                      <h4 className="rupee">₹</h4>
                      <h1 className="offerPrice">{sixDaysPlans.listedPrice}</h1>
                      <h1 className="planName">{sixDaysPlans.title}</h1>
                      <h4 className="oldPrice">₹ {sixDaysPlans.amount}</h4>
                    </Col>
                  )}
                </Row>
              </Container>
            </>
          )}
        </>
      ) : (
        <Container style={{ padding: "20px", minHeight: "55rem" }}>
          <h1
            style={{
              position: "absolute",
              top: "50%",
              right: "50%",
            }}
          >
            {" "}
            No Records
          </h1>
        </Container>
      )}
    </div>
  );
};

export default Subscription;
